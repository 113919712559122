import React from 'react';
import './slider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/swiper-bundle.css';
import 'swiper/less/navigation';
import 'swiper/css/bundle';
import { SliderProducts } from '../../data/products';


const Slider = () => {

    const duplicatedSlides = [...SliderProducts, ...SliderProducts];

    return (
        <div className="s-container">
            <Swiper
            breakpoints={{
                640: {
                    slidesPerView: 3
                },
                0: {
                    slidesPerView: 1
                },
            }}
            modules={[ Pagination, Navigation ]}
            className='mySwiper'
            loopFillGroupWithBlank={true}
            navigation={true}
            pagination={{
                dynamicBullets: true,
                clickable: true,
            }}
            slidesPerView={3}
            spaceBetween={40}
            slidesPerGroup={1}
            loop={true}
            observeParents={true}
            >
                    {duplicatedSlides.map((slide,i) => (
                        <SwiperSlide key={i}>
                            <div className="left-s" key={i}>
                                <div className="name">
                                    <span>
                                        {slide.name}
                                    </span>
                                    <span>
                                        {slide.detail}
                                    </span>
                                </div>
                                <span>
                                    {slide.price}$
                                </span>
                                <div>
                                    Shop Now
                                </div>
                            </div>
                            <img src={slide.img} alt={`product-${i}`} className='img-p' />
                        </SwiperSlide>
                    ))}
                
            </Swiper>

        </div>
    )
}

export default Slider;