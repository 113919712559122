import React from 'react';
import css from './Footer.module.css';
import Logo from '../../assets/logo2.png';
import { InboxIcon, PhoneIcon, MapPinIcon, ArrowLeftEndOnRectangleIcon , UsersIcon, LinkIcon } from '@heroicons/react/24/outline';

const Footer = () => {
    return (
        <div className={css.cFoteerWrapper}>
            <hr/>
            <div className={css.cFooter}>
                <div className={css.logo}>
                    <img src={Logo} alt="" />
                    <span>ShopoTube</span>
                </div>
                <div className={css.block}>
                    <div className={css.detail}>
                        <span>Contact Us</span>

                        <span className={css.pngLine}>
                            <MapPinIcon className={css.icon} />
                            <span>
                                Ukraine, Mykolaiv, 54058
                            </span>
                        </span>

                        <span className={css.pngLine}>
                            {''}
                            <PhoneIcon className={css.icon} />
                            <a href="tel:350-444-2354">350-444-2354</a>
                        </span>

                        <span className={css.pngLine}>
                            <InboxIcon className={css.icon} />
                            <a href="mailto:terra4beat@gmail.com">terra4beat@gmail.com</a>
                        </span>
                    </div>
                </div>

                <div className={css.block}>
                    <div className={css.detail}>
                        <span>
                            Account
                        </span>
                        <span className={css.pngLine}>
                            <ArrowLeftEndOnRectangleIcon className={css.icon} />
                            Sign In
                        </span>
                    </div>
                </div>

                <div className={css.block}>
                    <div className={css.detail}>
                        <span>
                            Company
                        </span>
                        <span className={css.pngLine}>
                            <UsersIcon className={css.icon} />
                            <a href="/about">
                                <p>About us</p>
                            </a>
                        </span>
                    </div>
                </div>

                <div className={css.block}>
                    <div className={css.detail}>
                        <span>
                            Resources
                        </span>
                        <span className={css.pngLine}>
                            <LinkIcon className={css.icon} />
                            <p>
                                Safety Privacy& Terms
                            </p>
                        </span>
                    </div>
                </div>
            </div>

            <div className={css.copyRight}>
                <span>
                    Copyright @2024 by ShopoTube, Inc.
                </span>
                <span>
                    All Rights Reserved.
                </span>
            </div>
        </div>
    )
}

export default Footer;